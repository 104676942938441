import {
  Alert,
  Box,
  Button,
  FormControl,
  FormHelperText,
  SelectChangeEvent,
  Snackbar,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { AccountHolderDto } from "../../../entities/AccountHolderDto";
import { CauseCategory } from "../../../entities/CauseCategoryDto";
import { getAccountHoldersForCauseCreation } from "../../../services/accountHolderService";
import { getCauseCategories } from "../../../services/causeCategoryService";
import React from "react";
import { TextFieldComponent } from "../../Form/TextFieldComponent";
import { SingleSelectComponent } from "../../Form/SingleSelectComponent";
import { MultiSelectComponent } from "../../Form/MultiSelectComponent";
import { CauseInputDto } from "../../../entities/CauseInputDto";
import { createCause } from "../../../services/causeService";
import { CauseDto } from "../../../entities/CauseDto";

function CauseCreation() {
  const [accountHolders, setAccountHolders] = useState<AccountHolderDto[]>([]);
  const [causeCategories, setCauseCategories] = useState<string[]>([]);

  const [selectedAccountHolder, setSelectedAccountHolder] =
    useState<string>("");
  const [selectedCauseCategories, setSelectedCauseCategories] = useState<
    string[]
  >([]);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [logo, setLogo] = useState<string>("");
  const [addressLine1, setAddressLine1] = useState<string>("");
  const [addressLine2, setAddressLine2] = useState<string>("");
  const [postCode, setPostCode] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [countryCode, setCountryCode] = useState<string>("FRA");

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorSelectedCategories, setErrorSelectedCategories] =
    React.useState<boolean>();

  const [errors, setErrors] = useState({
    name: "",
    description: "",
    logo: "",
    addressLine1: "",
    postCode: "",
    city: "",
  });

  const [cause, setCause] = useState<CauseDto>();
  const [errorCauseCreationMessage, setErrorCauseCreationMessage] =
    useState<string>("");
  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const isFormValid =
    !Object.values(errors).some((error) => error) &&
    name &&
    description &&
    logo &&
    addressLine1 &&
    postCode &&
    city &&
    countryCode &&
    selectedCauseCategories;

  useEffect(() => {
    async function fetchData() {
      setErrorMessage("");
      try {
        const accountHolderResponse = await getAccountHoldersForCauseCreation();
        const causeCategoriesResponse = await getCauseCategories();
        setAccountHolders(accountHolderResponse);
        if (accountHolderResponse.length > 0) {
          setSelectedAccountHolder(accountHolderResponse[0].id);
        }
        setCauseCategories(
          causeCategoriesResponse.map(
            (category: CauseCategory) => category.code
          )
        );
      } catch (error) {
        setErrorMessage("Could not fetch data, with error: " + error);
      }
    }
    fetchData();
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => {
    const { value } = e.target;

    if (field === "name") {
      setName(value);
      setErrors((prev) => ({
        ...prev,
        name: value ? "" : "Name is required",
      }));
    }

    if (field === "description") {
      setDescription(value);
      setErrors((prev) => ({
        ...prev,
        description: value ? "" : "Description is required",
      }));
    }

    if (field === "logo") {
      setLogo(value);
      setErrors((prev) => ({
        ...prev,
        logo: value ? "" : "Logo is required",
      }));
    }

    if (field === "addressLine1") {
      setAddressLine1(value);
      setErrors((prev) => ({
        ...prev,
        addressLine1: value ? "" : "Address Line 1 is required",
      }));
    }

    if (field === "postCode") {
      setPostCode(value);
      setErrors((prev) => ({
        ...prev,
        postCode: value ? "" : "Post Code is required",
      }));
    }

    if (field === "city") {
      setCity(value);
      setErrors((prev) => ({
        ...prev,
        city: value ? "" : "City is required",
      }));
    }
  };

  const handleAccountHolderChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | { value: unknown }
    >
  ) => {
    setSelectedAccountHolder(event.target.value as string);
  };

  const handleCauseCategoryChange = (
    event: SelectChangeEvent<typeof selectedCauseCategories>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedCauseCategories(
      typeof value === "string" ? value.split(",") : value
    );
    setErrorSelectedCategories(false);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (isFormValid) {
      if (selectedCauseCategories.length === 0) {
        setErrorSelectedCategories(true);
      } else {
        setErrorSelectedCategories(false);
        const input = new CauseInputDto(
          name,
          description,
          selectedCauseCategories,
          logo,
          selectedAccountHolder,
          addressLine1,
          addressLine2,
          postCode,
          city,
          countryCode
        );
        try {
          const response = await createCause(input);
          setCause(response);
          setOpenSnackbar(true);
          setIsFormDisabled(true);
        } catch (error) {
          setErrorCauseCreationMessage(
            "Could not create cause, with error: " + error
          );
        }
      }
    }
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography component="h2" variant="h2">
        Cause Creation
      </Typography>
      <form>
        <FormControl error={!!errorMessage}>
          {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
        </FormControl>

        <SingleSelectComponent
          name="accountHolder"
          label="Swan Account Holder Id"
          value={selectedAccountHolder}
          onChange={handleAccountHolderChange}
          options={accountHolders}
          optionLabelKey="swanAccountHolderId"
          optionValueKey="id"
          disabled={isFormDisabled}
        />

        <TextFieldComponent
          name="name"
          label="Name"
          value={name}
          onChange={(e) => handleChange(e, "name")}
          maxLength={255}
          error={!!errors.name}
          helperText={errors.name}
          disabled={isFormDisabled}
        />

        <TextFieldComponent
          name="description"
          label="Description"
          value={description}
          onChange={(e) => handleChange(e, "description")}
          multiline
          rows={5}
          maxLength={5000}
          error={!!errors.description}
          helperText={errors.description}
          disabled={isFormDisabled}
        />

        <MultiSelectComponent
          name="categories"
          label="Cause Categories"
          value={selectedCauseCategories}
          onChange={handleCauseCategoryChange}
          options={causeCategories}
          disabled={isFormDisabled}
        />

        <FormControl error={!!errorSelectedCategories}>
          {errorSelectedCategories && (
            <FormHelperText>At least 1 category is required</FormHelperText>
          )}
        </FormControl>

        <TextFieldComponent
          name="logo"
          label="Logo File Name (with Extension)"
          value={logo}
          onChange={(e) => handleChange(e, "logo")}
          maxLength={255}
          error={!!errors.logo}
          helperText={errors.logo}
          disabled={isFormDisabled}
        />

        <TextFieldComponent
          name="address-line-1"
          label="Address Line 1"
          value={addressLine1}
          onChange={(e) => handleChange(e, "addressLine1")}
          maxLength={255}
          error={!!errors.addressLine1}
          helperText={errors.addressLine1}
          disabled={isFormDisabled}
        />

        <TextFieldComponent
          name="address-line-2"
          label="Address Line 2"
          value={addressLine2}
          onChange={(e) => setAddressLine2(e.target.value)}
          maxLength={255}
          disabled={isFormDisabled}
        />

        <TextFieldComponent
          name="postCode"
          label="Post Code"
          value={postCode}
          onChange={(e) => handleChange(e, "postCode")}
          maxLength={5}
          error={!!errors.postCode}
          helperText={errors.postCode}
          disabled={isFormDisabled}
        />

        <TextFieldComponent
          name="city"
          label="City"
          value={city}
          onChange={(e) => handleChange(e, "city")}
          maxLength={255}
          error={!!errors.city}
          helperText={errors.city}
          disabled={isFormDisabled}
        />

        <TextFieldComponent
          name="country-code"
          label="Country Code"
          value={countryCode}
          onChange={(e) => setCountryCode(e.target.value)}
          maxLength={3}
          disabled
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={handleSubmit}
          disabled={!isFormValid || isFormDisabled}
        >
          Submit
        </Button>

        <FormControl error={!!errorCauseCreationMessage} sx={{ mt: 3 }}>
          {errorCauseCreationMessage && (
            <FormHelperText>
              Oups, something went wrong during cause creation
            </FormHelperText>
          )}
        </FormControl>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity="success"
            sx={{ width: "100%" }}
          >
            Cause {cause?.name} is created
          </Alert>
        </Snackbar>
      </form>
    </Box>
  );
}

export default CauseCreation;
